@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
	font-family: "Optimistic Display";
	src: url("./assets/fonts/Optimistic_Display/EOT/Optimistic_Display_W_Md.eot");
	src: url("./assets/fonts/Optimistic_Display/EOT/Optimistic_Display_W_Md.eot?#iefix")
			format("embedded-opentype"),
		url("./assets/fonts/Optimistic_Display/WOFF/Optimistic_Display_W_Md.woff")
			format("woff");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Optimistic Display";
	src: url("./assets/fonts/Optimistic_Display/EOT/Optimistic_Display_W_Bd.eot");
	src: url("./assets/fonts/Optimistic_Display/EOT/Optimistic_Display_W_Bd.eot?#iefix")
			format("embedded-opentype"),
		url("./assets/fonts/Optimistic_Display/WOFF/Optimistic_Display_W_Bd.woff")
			format("woff");
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: "Optimistic Text";
	src: url("./assets/fonts/Optimistic_Text/EOT/Optimistic_Text_W_Rg.eot");
	src: url("./assets/fonts/Optimistic_Text/EOT/Optimistic_Text_W_Rg.eot?#iefix")
			format("embedded-opentype"),
		url("./assets/fonts/Optimistic_Text/WOFF/Optimistic_Text_W_Rg.woff")
			format("woff");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Optimistic Text";
	src: url("./assets/fonts/Optimistic_Text/EOT/Optimistic_Text_W_Bd.eot");
	src: url("./assets/fonts/Optimistic_Text/EOT/Optimistic_Text_W_Bd.eot?#iefix")
			format("embedded-opentype"),
		url("./assets/fonts/Optimistic_Text/WOFF/Optimistic_Text_W_Bd.woff")
			format("woff");
	font-weight: bold;
	font-style: normal;
}

body {
	background-color: black;
	color: white;
	font-family: "Optimistic Text", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
		"Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.page-container {
	width: 100vw;
	height: 100vh;
}

.bg-splash {
	position: relative;
	background-image: url("./assets/images/bg-1.png");
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}

.bg-gradient {
	background: linear-gradient(180deg, #000000 0%, #221133 100%);
}

.bg-gradient-navbar {
	background: linear-gradient(180deg, #000000 30%, #66000000 100%);
}

.tick > text {
	fill: currentColor;
	fill-opacity: 0.5;
}

.mol-container {
	position: relative;
	width: 100%;
	height: 200px;
	border-radius: 0.75rem;
	overflow: hidden;
}

.tooltip_hmap {
	position: absolute;
	background-color: lightgrey;
	color: black;
	border-radius: 2px;
	font-size: x-small;
	opacity: 0;
	width: 6rem;
	padding: 5px;
	overflow: visible;
}

.mol_ .msp-plugin .msp-sequence-wrapper-non-empty {
	background-color: #cdcfd4 !important;
	color: black !important;
	overflow-x: visible !important;
	overflow-y: visible !important;
}

.mol_ .msp-plugin .msp-sequence-wrapper .msp-sequence-present {
	background-color: #cdcfd4 !important;
	color: #000000 !important;
}

.spinner_img {
	width: 50px;
	height: 50px;
	margin: 75px auto auto auto;
}

/* Custom Table */

.table.seq_table :where(thead, tfoot) :where(th, td) {
	background-color: #d6dae8;
}

.table.seq_table tr.hover:hover td {
	background-color: rgba(76, 39, 182, 0.07);
}

.table.seq_table td a {
	text-decoration: underline;
}

.table.seq_table td a:hover {
	color: #4c27b6;
}

.table-compact :where(th, td) {
	text-transform: none;
}

.seq_table th:first-child {
	position: relative;
}

/* text error alert */
.alert-shown {
	opacity: 1;
	transition: all 250ms linear;
}

.alert-hidden {
	opacity: 0;
	transition: all 250ms linear 2s;
}

/*** D3FC cluster ***/

#chart-container {
	flex: 1;
	position: relative;
}

#chart-container > div {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	text-align: middle;
	vertical-align: middle;
}

#chart d3fc-group {
	grid-template-columns: 0em auto 1fr 0em 0;
	grid-template-rows: 0 auto 1fr 0em 0;
}
